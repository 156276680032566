.gradeContainer {
  position: relative;
  border-radius: 10px;
  margin-inline-end: 15px;
  width: 70px;
  height: 70px;
}
.attendanceGraph {
  margin-top: 30px;
  overflow-y: auto;
}
.scores_wrapper::-webkit-scrollbar {
  width: 2px;
}
/* Track */
.scores_wrapper::-webkit-scrollbar-track {
  background: red;
}
/* Handle */
.scores_wrapper::-webkit-scrollbar-thumb {
  background: #ffd252;
}

.scrollable-element::-webkit-scrollbar {
  width: 5px;
}
.scrollable-element {
  scrollbar-width: thin;
  scrollbar-color: #ffd252 yellow;
  display: flex;
  justify-content: center;
}
.scrollable-element::-webkit-scrollbar-track {
  background: #f7f7f7;
}
.scrollable-element::-webkit-scrollbar-thumb {
  background-color: #ffd252;
  border-radius: 6px;
  border: 3px solid #ffd252;
}

/* Apply styles for all screen sizes */
.reportPageContainer {
  padding: 20px;
  box-sizing: border-box;
}

.reportHeader-grid-columns {
  grid-template-columns: 1fr 1fr 1fr;
}

.renderPerformance-grid-columns {
  grid-auto-flow: column;
  grid-template-columns: 1fr 0.5fr 3fr;
}
.renderDailyPerformance-grid-columns {
  grid-auto-flow: column;
  /* grid-template-columns: 20fr 20fr 20fr; */
}
.renderMistakes-columns {
  grid-auto-flow: column;
}

.grade-border {
  border-right: 2px solid #ececec;
  border-left: 2px solid #ececec;
}

.verticalDivider {
  display: block;
}
.horizontalDivider {
  display: none;
}
.attendance-graph-index {
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 50%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  color: #999999;
  font-size: 13px;
  width: 40px;
  height: 40px;
}
.attendance-graph-index {
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 50%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  color: #999999;
  font-size: 13px;
  width: 40px;
  height: 40px;
}
.attendance-home-graph-index {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  color: #999999;
  font-size: 13px;
  width: 40px;
  height: 40px;
}
.student-row {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.attendance-graph-index:hover {
  background-color: #c3e56e;
}
.audio-progress-bar {
  width: 100%;
  height: 35px; /* Set the height of your player */
  border-radius: 25px; /* Optional: if you want rounded corners */
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  /* Smooth transition for background color */
}

/* For tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .reportPageContainer {
    margin: 20px;
    padding: 10px;
  }
  .reportHeader-grid-columns {
    grid-template-columns: 1fr;
  }
  .renderPerformance-grid-columns {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
  .renderMistakes-columns {
    grid-auto-flow: row;
  }
  .grade-border {
    border-top: 2px solid #ececec;
    border-bottom: 2px solid #ececec;
    border-right: none;
    border-left: none;
  }
  .verticalDivider {
    display: none;
  }
  .horizontalDivider {
    display: block;
  }
  .flex-algin-center {
    display: flex;
    justify-content: center;
  }
}

/* For mobile devices */
@media (max-width: 767px) {
  .reportPageContainer {
    margin: 10px;
    padding: 5px;
  }
  .reportHeader-grid-columns {
    grid-template-columns: 1fr;
  }
  .renderPerformance-grid-columns {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
  .renderMistakes-columns {
    grid-auto-flow: row;
  }
  .grade-border {
    border-top: 2px solid #ececec;
    border-bottom: 2px solid #ececec;
    border-right: none;
    border-left: none;
  }
  .verticalDivider {
    display: none;
  }
  .horizontalDivider {
    display: block;
  }
  .flex-algin-center {
    display: flex;
    justify-content: center;
  }
  .student-row {
    margin-right: 10px;
  }
  .gradeContainer {
    width: 50px;
    height: 50px;
  }
  .studentTextContainer {
    max-width: 110px;
  }
}
