.loader_page {
  width: 100%;
  z-index: 10;
  height: 100%;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.384);
  justify-content: space-around;
  align-items: center;
}

.loader_box {
  display: flex;

  background: rgba(0, 0, 0, 0.384);
  width: 300px;
  height: 180px;
  flex-direction: column;

  background: #ffffff;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
  border-radius: 10px;
}
.loader_desc {
  font-size: 10px;
  text-align: center;
}
