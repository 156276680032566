.scores_wrapper::-webkit-scrollbar {
  width: 2px;
}
/* Track */
.scores_wrapper::-webkit-scrollbar-track {
  background: red;
}
/* Handle */
.scores_wrapper::-webkit-scrollbar-thumb {
  background: #ffd252;
}

.scrollable-element::-webkit-scrollbar {
  width: 5px;
}
.scrollable-element {
  scrollbar-width: thin;
  scrollbar-color: #ffd252 yellow;
  display: flex;
  justify-content: center;
}
.scrollable-element::-webkit-scrollbar-track {
  background: #f7f7f7;
}
.scrollable-element::-webkit-scrollbar-thumb {
  background-color: #ffd252;
  border-radius: 6px;
  border: 3px solid #ffd252;
}

/* Apply styles for all screen sizes */
.reportPageContainer {
  margin-right: 60px;
  margin-left: 60px;
  padding: 20px;
  box-sizing: border-box;
}

.reportHeader-grid-columns {
  grid-template-columns: 1fr 1fr 1fr;
}

.renderPerformance-grid-columns {
  grid-auto-flow: column;
  grid-template-columns: 1fr 0.5fr 3fr;
}

.renderMistakes-columns {
  grid-auto-flow: column;
}

.grade-border {
  border-right: 2px solid #ececec;
  border-left: 2px solid #ececec;
}

.verticalDivider {
  display: block;
}
.horizontalDivider {
  display: none;
}
.modal-progress-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.modal-question-container {
  margin-top: 15px;
}
.scrollable-mistakes {
  overflow-x: auto;
  white-space: nowrap;
  max-height: 300px;
  scrollbar-width: thin;
  scrollbar-color: #ffd252 yellow;
  border-radius: 6px;
}
.list-item-container {
  display: inline-flex;
  margin-right: 20px;
  margin-bottom: 20px;
}
.mistake-style {
  align-content: center;
  padding: 10px 20px;
  border-radius: 15px;
  margin-right: 10px;
  border: 0.5px solid #ececec;
  font-family: 'Roboto';
  color: rgb(242, 64, 106);
  font-size: 18px;
  display: block;
}

.renderMistakes-columns-student {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Responsive grid */
  gap: 20px;
  padding-bottom: 30px;
}

/* For tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .reportPageContainer {
    margin: 20px;
    padding: 10px;
  }
  .reportHeader-grid-columns {
    grid-template-columns: 1fr;
  }
  .renderPerformance-grid-columns {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
  .renderMistakes-columns {
    grid-auto-flow: row;
  }
  .grade-border {
    border-top: 2px solid #ececec;
    border-bottom: 2px solid #ececec;
    border-right: none;
    border-left: none;
  }
  .verticalDivider {
    display: none;
  }
  .horizontalDivider {
    display: block;
  }
  .flex-algin-center {
    display: flex;
    justify-content: center;
  }
}

/* For mobile devices */
@media (max-width: 767px) {
  .reportPageContainer {
    margin: 10px;
    padding: 5px;
  }
  .reportHeader-grid-columns {
    grid-template-columns: 1fr;
  }
  .renderPerformance-grid-columns {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
  .renderMistakes-columns {
    grid-auto-flow: row;
  }
  .grade-border {
    border-top: 2px solid #ececec;
    border-bottom: 2px solid #ececec;
    border-right: none;
    border-left: none;
  }
  .verticalDivider {
    display: none;
  }
  .horizontalDivider {
    display: block;
  }
  .flex-algin-center {
    display: flex;
    justify-content: center;
  }
}
