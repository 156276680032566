.auth-btn {
  background-color: #40b2f2 !important;
  padding: 0.8rem !important;
  font-weight: bold;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-size: 20px;
}
.auth-btn:hover {
  box-shadow: 0px 4px 0px -1px rgba(61, 167, 227, 1);
  -webkit-box-shadow: 0px 4px 0px -1px rgba(61, 167, 227, 1);
  -moz-box-shadow: 0px 4px 0px -1px rgba(61, 167, 227, 1);
  transform: scale(1.01);
}
.login-card {
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}
.login-logo {
  width: 8rem;
}
.form-control {
  background: #f7f7f7 !important;
  border-radius: 10px 0px 0px 10px;
  height: 59px;
  border: none;
}
.input-group-text {
  border: none !important;
}
.form-control > div {
  background-color: #f7f7f7;
}
.input-group > .form-control::placeholder {
  font-size: 17px;
  line-height: 30px;
  color: #dbdbdb;
}
.classera-btn {
  width: 100%;
  font-size: 20px !important;
  padding: 7px 10px;
  border-radius: 10px !important;
  text-align: center;
  display: block;
  text-decoration: none;
  font-weight: bold !important;
  border: none !important;
}

@media (min-width: 768px) {
  .login-card {
    width: 33.33%;
  }
}
