@font-face {
  font-family: 'Roboto' !important;
  src: url(./assets/font/arialroundedmtbold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Roboto', 'Tajawal', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-list,
.ant-layout,
.ant-typography,
.ant-menu,
.ant-tabs,
.ant-btn,
.ant-table {
  font-family: 'Roboto', 'Tajawal', sans-serif !important;
}
.ant-tabs-ink-bar {
  background-color: #40b2f2 !important;
}
.ant-table-body {
  scrollbar-width: thin;
  scrollbar-color: #ffd252 yellow;
}
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #ffd252;
  border-radius: 4px;
}
.ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: #ffd252;
}
.ant-table-body::-webkit-scrollbar-track {
  background-color: #ffd252;
  border-radius: 4px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-tabs-nav {
  margin: 0 !important;
}

.bg-pikado-blue {
  background-color: #40b2f2 !important;
  color: white !important;
  border-bottom: 5px solid #1e9ae0 !important;
}
