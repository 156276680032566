.modalOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* background-color: rgba(0, 0, 0, 0.9); */
}

.modalCard {
    background-color: #f2f2f2;
    padding: 20px;
    max-width: 90%;
    margin: auto
}

.homeworkParagraph{
    resize: none;
    max-width: 98%;
}
.homeworkParagraph:focus-visible{
    /* border: 2px solid #1e9ae0 !important; */
    outline-color: #1e9ae0 !important;
}
